import React from 'react'
import Footer from '../../components/Footer';

const Refunds = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                <span className='font-semibold text-md pb-1'>{item.header}
                                </span>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-4'>
                                        {item.subItems.map((subItem) => (
                                            <>
                                                {subItem.id === 1 ? (
                                                    <li key={subItem.id} className='w-full flex flex-col justify-start items-start pt-3'>
                                                        1.2. To initiate a refund, please contact our customer service team at info@alleshealth.com within 7 days of your purchase. Provide your order details and a clear reason for your refund request.
                                                    </li>
                                                ) : (
                                                    <li key={subItem.id} className='w-full flex flex-col justify-start items-start pt-3'>
                                                        <span className='font-semibold text-md pb-1'>{subItem.header}</span> {subItem.text}
                                                    </li>
                                                )}
                                            </>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default Refunds

const DATA = [
    {
        id: 0,
        header: 'Refund & Cancellation Policy For AllesHealth App ⇒ Subscription-Based Model',
        items: [
            {
                id: 0,
                text: 'At AllesHealth, we value our customers and aim to provide a seamless and satisfying experience with our subscription-based services. Please review our refund and cancellation policy outlined below:',
                subItems: [
                    {
                        id: 0,
                        header: 'Subscription Cancellation:',
                        text: 'You have the right to cancel your subscription to the AllesHealth app at any time.',
                    },
                    {
                        id: 1,
                        text: 'To cancel your subscription, you can do so within the app by accessing your account settings or contacting our customer support team for assistance.',
                    },
                    {
                        id: 2,
                        header: 'Refund Eligibility:',
                        text: 'We do not offer refunds for subscription fees already paid, except in the following circumstances: a) Technical issues or service disruptions preventing access to the app for an extended period, subject to verification by our technical team. b) Duplicate charges or billing errors, which will be promptly rectified upon notification. c) Dissatisfaction with the quality of services provided, subject to management\'s discretion and review.',
                    },
                    {
                        id: 3,
                        header: 'Refund Request Process:',
                        text: 'If you believe you are eligible for a refund, please submit a written refund request within 7 working days of the incident or billing error.',
                    },
                    {
                        id: 4,
                        text: 'Clearly state the reason for your refund request and provide any supporting documentation or evidence to assist in the review process.',
                    },
                    {
                        id: 5,
                        text: 'Refund requests can be submitted by contacting our customer support team via email or through the in-app support system.',
                    },
                    {
                        id: 6,
                        header: 'Refund Approval and Disbursement:',
                        text: 'All refund requests will be thoroughly reviewed and assessed by our team.',
                    },
                    {
                        id: 7,
                        text: 'If your refund request is approved, we will process the refund using the original payment method used during the subscription purchase.',
                    },
                    {
                        id: 8,
                        text: 'The refund will be disbursed promptly, but please note that the time taken for the refund to reflect in your account may vary based on the policies of your financial institution.',
                    },
                    {
                        id: 9,
                        header: 'Changes to Subscription Plans:',
                        text: 'AllesHealth reserves the right to modify or discontinue subscription plans, features, or pricing at any time. In such cases, you will be provided with prior notice, and you may choose to continue or cancel your subscription accordingly.',
                    },
                ],
            },
            {
                id: 1,
                text: 'We encourage our users to carefully consider their subscription purchases and reach out to our customer support team for any assistance or inquiries prior to subscribing. Our team is always ready to help address any concerns you may have.',
            },
        ],
    },
    {
        id: 1,
        header: 'Refund & Cancellation Policy For AllesHealth ⇒ Services (E.G. Lab Tests, TeleHealth Consultation, Products)',
        items: [
            {
                id: 0,
                text: 'Thank you for choosing AllesHealth App Services. We aim to provide you with the best possible healthcare experience. However, we understand that there may be situations where you need to cancel or seek a refund for the services you have availed. To ensure transparency and clarity, we have developed the following Refund & Cancellation Policy:',
                subItems: [
                    {
                        id: 0,
                        header: 'Refund Policy:',
                        text: '1.1. AllesHealth App Services offers a 7-day refund policy from the date of purchase providing those services have not been consumed within this period, you are eligible for a refund.',
                    },
                    {
                        id: 1,
                        text: '',
                    },
                    {
                        id: 2,
                        text: '1.3. Once your refund request is received, we will review it and notify you of the approval or rejection within a reasonable timeframe.',
                    },
                    {
                        id: 3,
                        text: '1.4. If your refund request is approved, we will process the refund to the original payment method used during the purchase. Please note that it may take a few business days for the refund to reflect in your account.',
                    },
                    {
                        id: 4,
                        header: 'Exceptions:',
                        text: '2.1. Our refund policy does not cover situations where the services have been fully or partially utilized within the 7-day refund period.',
                    },
                    {
                        id: 5,
                        text: '2.2. The refund policy does not apply to any products, equipment, or services purchased through third-party providers. Such purchases are subject to the refund and cancellation policies of the respective providers.',
                    },
                    {
                        id: 6,
                        header: 'Contact Information:',
                        text: 'For any questions, concerns, refund requests, or cancellations, please reach out to our customer service team at info@alleshealth.com. We are here to assist you and provide the necessary support.',
                    },
                ],
            },
            {
                id: 1,
                text: 'Please read and understand our Refund & Cancellation Policy before availing our services. By using AllesHealth App Services, you acknowledge and agree to comply with the terms and conditions outlined in this policy.',
            },
            {
                id: 2,
                text: 'Note: This policy is subject to change without prior notice. Please refer to the latest version available on the AllesHealth website or contact customer service for any updates.',
            },
        ],
    },
]