import React from 'react'
import Footer from '../../components/Footer';

const TermsCondition = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                <span className='font-semibold text-md pb-1'>{item.header}
                                </span>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-4'>
                                        {item.subItems.map((subItem) => (
                                            <li key={subItem.id} className='w-full flex flex-col justify-start items-start pt-3'>
                                                <span className='font-semibold text-md pb-1'>{subItem.header}</span> {subItem.text}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default TermsCondition

const DATA = [
    {
        id: 0,
        header: 'Terms & Conditions For AllesHealth App Usage In India',
        items: [
            {
                id: 0,
                text: 'These Terms & Conditions (“Agreement”) govern your use of the AllesHealth mobile application (“App”) provided by AllesHealth GmbH (“AllesHealth,” “we,” or “us”), marketed by Swiss AllesHealth India Pvt Ltd (“Swiss AllesHealth”) in India. By downloading, installing, accessing, or using the App, you (“User,” “you,” or “your”) agree to be bound by this Agreement. If you do not agree to these terms, please do not use the App.',
                subItems: [
                    {
                        id: 0,
                        header: 'App Usage:',
                        text: '1.1. The App is intended for personal use to facilitate access to healthcare services and related information.',
                    },
                    {
                        id: 1,
                        text: '1.2. You must be at least 18 years old to use the App. If you are below the legal age, you may use the App under the supervision of a parent or legal guardian.',
                    },
                    {
                        id: 2,
                        header: 'Account Registration:',
                        text: '2.1. To use certain features of the App, you may be required to create an account and provide accurate and complete information.',
                    },
                    {
                        id: 3,
                        text: '2.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.',
                    },
                    {
                        id: 4,
                        text: '2.3. You agree to promptly notify AllesHealth of any unauthorized use or breach of security related to your account.',
                    },
                    {
                        id: 5,
                        header: 'Use of App Services:',
                        text: '3.1. The App allows you to access healthcare-related services, including but not limited to scheduling appointments, accessing medical records, and communicating with healthcare providers.',
                    },
                    {
                        id: 6,
                        text: '3.2. You understand and acknowledge that AllesHealth does not provide medical advice or diagnosis. The App is solely a platform to facilitate healthcare services.',
                    },
                    {
                        id: 7,
                        header: 'Privacy:',
                        text: '4.1. AllesHealth respects your privacy and handles personal data in accordance with applicable data protection laws and our Privacy Policy.',
                    },
                    {
                        id: 8,
                        text: '4.2. By using the App, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.',
                    },
                    {
                        id: 9,
                        header: 'Intellectual Property:',
                        text: '5.1. The App and its contents, including but not limited to text, graphics, logos, and software, are owned by AllesHealth and protected by intellectual property laws.',
                    },
                    {
                        id: 10,
                        text: '5.2. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the App.',
                    },
                    {
                        id: 11,
                        header: 'Limitation of Liability:',
                        text: '6.1. AllesHealth shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with your use of the App.',
                    },
                    {
                        id: 12,
                        text: '6.2. AllesHealth does not guarantee the accuracy, completeness, or reliability of any information provided through the App.',
                    },
                ],
            },
            {
                id: 1,
                header: 'Indemnification:',
                text: ' You agree to indemnify, defend, and hold harmless AllesHealth and its affiliates, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or related to your use of the App or any violation of this Agreement.',
            },
            {
                id: 2,
                header: 'Modifications to the App and Agreement:',
                text: ' AllesHealth reserves the right to modify or discontinue the App, or any part thereof, with or without notice. We may also update or change this Agreement from time to time. Your continued use of the App after any modifications to this Agreement constitutes your acceptance of the revised terms.',
            },
            {
                id: 3,
                header: 'Modifications to the App and Agreement:',
                text: 'Governing Law and Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of Switzerland. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in Basel-Land.',
            },
            {
                id: 4,
                header: 'Severability:',
                text: ' If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue to be valid to the fullest extent permitted by law.',
            },
            {
                id: 5,
                header: 'Entire Agreement:',
                text: ' This Agreement constitutes the entire agreement between you and AllesHealth regarding the use of the App and supersedes any prior agreements or understandings.',
            },
            {
                id: 6,
                header: 'Legal Relationship:',
                text: ' Nothing in this Agreement shall be deemed to create a partnership, joint venture, agency, or employment relationship between you, AllesHealth, and Swiss AllesHealth.',
            },
            {
                id: 7,
                text: 'If you have any questions or concerns regarding this Agreement, please contact us at info@alleshealth.com',
            },
            {
                id: 8,
                text: 'Note: This Agreement is subject to change without prior notice. Please refer to the latest version available on the AllesHealth website or within the App for any updates.',
            },
        ],
    },
]