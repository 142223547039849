import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';

const LandingTile2 = ({
    bgImage,
    headerText,
    headerTop,
    headerFont,
    headerColor,
    mobileHeaderColor,
    subparagraphText,
    subColor,
    bottomColor,
    mobileText,
}) => {
    const navigate = useNavigate();
    return (
        <>
            {/* DESKTOP LANDING TILE */}
            <div className="hidden md:flex w-full h-40vh bg-cover bg-center flex justify-center items-center relative">
                {/* BG Image */}
                <div style={{ borderBottomColor: bottomColor, overflow: 'hidden' }} className="homeBg w-full h-[80vh] border-b-[20px]">
                    <LazyLoadImage
                        src={bgImage}
                        effect="blur"
                        width={'100vw'}
                        height={'80vh'}
                        placeholderSrc={bgImage}
                        alt="Background Image"
                        className="w-full h-[80vh] object-cover" />
                </div>

                {/* Text Right */}
                <div className={`p-6 rounded-md absolute w-1/2 lg:w-[28vw] xl:w-[30vw] text-left right-5 ${headerTop ? `w-1/2 top-[${headerTop}vw]` : 'top-12 lg:top-12 xl:top-[3vw]'} flex flex-col`}>
                    <h1
                        style={{ color: headerColor }}
                        className={`${headerFont ? `text-[3vw] md:max-lg:text-[5vw] xl:${headerFont}` : 'lg:text-[2.5vw]'} text-4xl md:leading-[6vw] lg:leading-[5vw] font-bold mb-2`}>{headerText}</h1>
                    <p
                        style={{ color: subColor }}
                        className={`text-lg w-full md:leading-[3.5vw] lg:leading-[2.5vw] font-bold `}>{subparagraphText}</p>
                </div>

                {/* PROGRAM SUNITA */}
                <div onClick={()=> {navigate("/Sunita")}} className="bg-[#3CA5DC] border-b-[8px] border-l-[8px] border-t-[8px] flex flex-col w-1/2 lg:w-[30vw] xl:w-[23vw] justify-center align-center items-start px-5 lg:pl-8 lg:pr-2 py-4 absolute bottom-12 right-0 rounded-l-3xl cursor-pointer">
                    <h1
                        className={`text-lg text-left font-semibold text-[#FFFFFF]`}>PROGRAM <span className='text-[#FFD500]'>SUNITA</span></h1>
                    <p
                        className={`text-lg text-left font-semibold text-[#FFFFFF]`}>CERVICAL CANCER MUKT BHARAT</p>
                </div>
            </div>

            {/* MOBILE LANDING TILE */}
            <div className="block md:hidden w-full h-40vh bg-cover bg-center flex flex-col justify-center items-center">
                {/* BG Image */}
                <div style={{ borderBottomColor: bottomColor }} className="homeBg w-full border-b-[20px]">
                    <img src={bgImage} alt="Background Image" className="w-full h-auto object-cover" />
                </div>

                {/* Image Text */}
                <div className={`px-[10vw] w-full ${mobileText ? mobileText : 'text-left'} flex flex-col`}>
                    <h1
                        style={{ color: `${mobileHeaderColor ? mobileHeaderColor : '#000000'}` }}
                        className={`text-4xl leading-[6vh] font-bold mt-5`}>{headerText}</h1>
                    <p
                        style={{ color: '#2A79BC' }}
                        className={`text-lg leading-[4vh] font-bold mt-5`}>{subparagraphText}</p>
                </div>
            </div>
        </>
    )
}

export default LandingTile2;