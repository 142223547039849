import React from 'react'
import Footer from '../../components/Footer';

const DataPolicy = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>

                    {/* Responsible Party */}
                    <div
                        style={{ background: 'linear-gradient(90deg, rgba(123, 204, 246, 0.49) 40%, rgba(44, 138, 179, 0.22) 90%)' }}
                        className="w-[80vw] lg:w-[40vw] lg:mx-[20vw] flex flex-col justify-center items-center text-center rounded-xl border-4 border-[#008BD4] lg:py-5">
                        <h1 className="text-2xl font-semibold text-[#000000] text-center px-2 pb-3">Responsible Party</h1>
                        <h1 className="text-md font-semibold text-[#3CA5DC] text-center px-2 py-1">AllesHealth GmbH</h1>
                        <h1 className="text-md font-semibold text-[#3CA5DC] text-center px-2 py-1">Apfhalterstrasse 7B</h1>
                        <h1 className="text-md font-semibold text-[#3CA5DC] text-center px-2 py-1">Muttenz, 4132, Basel-Land</h1>
                        <h1 className="text-md font-semibold text-[#3CA5DC] text-center px-2 py-1">Switzerland</h1>
                        <a href="mailto:info@alleshealth.com" className="text-md font-semibold text-[#3CA5DC] text-center px-2 py-1">Email: info@alleshealth.com</a>
                    </div>

                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                {item.text}
                                {item.subItems && (
                                    <>
                                        <ul className='w-full flex flex-col justify-start items-start py-2'>
                                            {item.subItems.map((subItem) => (
                                                <>
                                                    {subItem.id === 16 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                If you have questions regarding this Privacy Policy or if you want to exercise your rights regarding the processing of your personal data as set out below, please contact us at <a href="mailto:info@alleshealth.com">info@alleshealth.com</a>
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 31 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                For more information about the AdRoll or LinkedIn advertising technology, including what information is collected about you, how it is used and why the cookies are dropped see the <a href="https://www.nextroll.com/privacy" className='underline'>AdRoll Privacy Policy</a> and <a href="https://www.linkedin.com/legal/privacy-policy"className='underline'>LinkedIn Privacy Policy</a> and the <a href="https://www.linkedin.com/legal/cookie-policy"className='underline'>LinkedIn Cookie Policy</a>.
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 32 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                You can <a href="https://www.smartpatient.eu/legal-information#" className='underline'>update your cookie choices</a> any time.
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 34 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                <a href="http://optout.networkadvertising.org/" className='underline'>Opt-out of interest-based advertising</a>
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 36 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                On behalf of the website provider, Google uses this information to evaluate your activities on this website and compile site-related activity reports and other internet-related services operated by the website provider. Your IP address will not be linked to other Google services or products. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full function-ality of this website. Furthermore you can prevent Google\'s collection and use of data (cookies and IP address) by downloading and installing the browser plug-in available under <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" className='underline'>tools.google.com/dlpage/gaoptout</a>
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 38 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                <a href="https://www.smartpatient.eu/legal-information#" className='underline'>Opt-out from GA tracking</a>
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : subItem.id === 39 ? (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                <li className='py-3'>
                                                                For further information on the terms and conditions of use and data privacy of Google please refer to <a href="https://policies.google.com/privacy?hl=en" className='underline'>policies.google.com/privacy</a>.
                                                                </li>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                                {subItem.header &&
                                                                    <li className='font-semibold text-md mt-5'>{subItem.header}</li>
                                                                }
                                                                <li className='py-3'>{subItem.text}</li>
                                                            </div>
                                                        </>
                                                    )
                                                    }
                                                </>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default DataPolicy

const DATA = [
    {
        id: 0,
        header: 'Data Privacy Policy',
        items: [
            {
                id: 0,
                subItems: [
                    {
                        id: 0,
                        header: '1. About Our Website And Our Products',
                        text: 'AllesHealth welcomes you to our website. Thank you for your interest in our company and our products.',
                    },
                    {
                        id: 1,
                        text: 'The protection and confidentiality of your personal data is very important to us. We collect and use your data in strict accordance with the EU General Data Protection Regulation (GDPR).',
                    },
                    {
                        id: 2,
                        text: 'In this Privacy Policy, we will inform you about the personal data we process, the extent to which, and the purpose for which we process this data when you use our website. You can access this Privacy Policy at any time on our website.',
                    },
                    {
                        id: 3,
                        text: 'The information provided by AllesHealth (\'we\', \'us\', or \'our\') on AllesHealth.com (the \'Site\') and our mobile application is for general informational purposes only. All information on the Site and our Mobile Application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application.',
                    },
                    {
                        id: 4,
                        text: 'UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUT MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK',
                    },
                    {
                        id: 5,
                        text: 'EXTERNAL LINKS DISCLAIMER',
                    },
                    {
                        id: 6,
                        text: 'The Site and our mobile application may contain (or you may be sent through the Site or our Mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability. Or completeness by us.',
                    },
                    {
                        id: 7,
                        text: 'WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY OF ANY INFORMATION OFFERED BY THIRD PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNED OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.',
                    },
                    {
                        id: 8,
                        text: 'PROFESSIONAL DISCLAIMER',
                    },
                    {
                        id: 9,
                        text: 'The site cannot and does not contain Medical/health advice. The medical/health information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of medical/health advice.',
                    },
                    {
                        id: 10,
                        text: 'THE USE OF RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.',
                    },
                    {
                        id: 11,
                        header: '2. Data Controller',
                        text: 'Data controller within the meaning of Art. 4 no. 7 GDPR is',
                    },
                    {
                        id: 12,
                        text: 'AllesHealth GmbH',
                    },
                    {
                        id: 13,
                        text: 'Apfhalterstrasse 7B',
                    },
                    {
                        id: 14,
                        text: 'Muttenz, 4132, Basel-Land',
                    },
                    {
                        id: 15,
                        text: 'Switzerland',
                    },
                    {
                        // empty items are hardcoded for Links in render part
                        id: 16,
                        text: '',
                    },
                    {
                        id: 17,
                        header: '3. Personal Data',
                        text: 'Subject of the data protection legislation is personal data. Personal data is any information relating to an identified or identifiable natural person. Examples of such data include your name or your email address (Art. 4 para. 1 GDPR).',
                    },
                    {
                        id: 18,
                        header: '4. Automated Data Collection',
                        text: '4.1 When entering our website, your device (e.g. your computer or smartphone) automatically transmits the following data to us:',
                    },
                    {
                        id: 19,
                        text: 'Date and time of access',
                    },
                    {
                        id: 20,
                        text: 'Operating System',
                    },
                    {
                        id: 21,
                        text: 'Type of device',
                    },
                    {
                        id: 22,
                        text: 'Quantity of data transmitted',
                    },
                    {
                        id: 23,
                        text: 'IP address',
                    },
                    {
                        id: 24,
                        text: '4.2 Processing this data is a technical requirement for the use of the website.',
                    },
                    {
                        id: 25,
                        header: '5. Newsletter',
                        text: '5.1 Provided you have given your consent, we will send you our newsletter free of charge. In order to send you the newsletter, we require your email address. The newsletter is our medium to inform you of new functionalities, new products and to send you general information about AllesHealth and other related products from AllesHealth GmbH.',
                    },
                    {
                        id: 26,
                        text: '5.2 If you have given your consent, we may use your personal data (including health-related data which you have provided us) to tailor the content of the newsletter to your needs.',
                    },
                    {
                        id: 27,
                        text: '5.3 You can unsubscribe from the newsletter at any time. Every newsletter will contain the information on how to unsubscribe.',
                    },
                    {
                        id: 28,
                        header: '6. Cookies',
                        text: 'This website uses cookies. Cookies are small text files that are stored on your computer via your web browser (e.g. Firefox or Safari) when you visit websites. Cookies allow us to make our website user-friendly. Most cookies you receive are automatically deleted after you leave our website. Some cookies can remain stored on your computer unless you manually delete them. The remaining cookies saved by your browser allow us to recognise whether you have been a visitor to our website before. You can set up notifications for when cookies are used in general, or for certain websites as well as allow or disable certain types of cookies in certain cases. You can also set up the automatic deletion of cookies when you close your browser. Please note that by disabling cookies, the website may not function properly. Where this is legally required, we obtain your prior consent for the use of cookies.',
                    },
                    {
                        id: 29,
                        text: 'We have listed the cookies we use below and explain which data is stored and for what purpose:',
                    },
                    {
                        id: 30,
                        text: 'In addition to those cookies, our advertising service partners, the AdRoll Group (AdRoll Inc, 2300 Harrison St, Fl 2 San Francisco CA, 94110 “AdRoll”) and LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Ireland, “LinkedIn”) set cookies on our webpages and collect information from your browser across the web. This information is used to better understand your interests in order to select and deliver relevant advertising on sites connected to their services.',
                    },
                    {
                        // empty items are hardcoded for Links in render part
                        id: 31,
                        text: '',
                    },
                    {
                        id: 32,
                        text: '',
                    },
                    {
                        id: 33,
                        text: 'Another option to easily opt out of most personalized Advertising services is provided by the Network Advertising Initiative Tool:',
                    },
                    {
                        id: 34,
                        text: '',
                    },
                    {
                        id: 35,
                        header: '7. Google Analytics',
                        text: 'This website uses Google Analytics, a web analytics service provided by Google. Google Analytics uses cookies to analyze and compile reports of users\' activities on our website. These cookies save basic information, such as your IP address, which is sent to a server at Google in the United States and stored.',
                    },
                    {
                        id: 36,
                        text: '',
                    },
                    {
                        id: 37,
                        text: 'You may object to the use of Google Analytics by clicking on the following link. An opt-out cookie will be set on the computer, which prevents the future collection of your data when visiting this website:',
                    },
                    {
                        id: 38,
                        text: '',
                    },
                    {
                        id: 39,
                        text: '',
                    },
                    {
                        id: 40,
                        header: '8. Retention Period',
                        text: '8.1 We retain your personal data for no longer than is necessary for the purposes for which your personal data are processed.',
                    },
                    {
                        id: 41,
                        text: '8.2 We delete any personal data we automatically collect on you during your use of the website immediately after the information is no longer necessary to provide you access to the website. For the deletion of cookies, please refer to the above section on Cookies.',
                    },
                    {
                        id: 42,
                        text: '8.3 We delete any personal data that you provide us for a specific purpose if this personal data is no longer needed for this purpose (e.g. we delete your email address from our newsletter database after you have unsubscribed from the newsletter).',
                    },
                    {
                        id: 43,
                        header: '9. Legal Basis For The Data Processing',
                        text: '9.1 We process your personal data which we collect on the website either on the basis of your consent pursuant to Art. 6 para. 1 lit. a) GDPR, as necessary for the performance of providing the website to you as set out in this Privacy Policy pursuant to Art. 6 para. 1 lit. b) GDPR or for the purpose of our legitimate interests pursuant to Art. 6 para. 1 lit. f) GDPR.',
                    },
                    {
                        id: 44,
                        text: '9.2 Where we process your personal data for the purpose of our legitimate interests pursuant to Art. 6 para. 1 lit. f) GDPR, our legitimate interests may be to continuously improve the security and usability of our website.',
                    },
                    {
                        id: 45,
                        header: '10. Your Legal Rights',
                        text: '10.1 Under the GDPR you have the following rights with regard to your personal data:',
                    },
                    {
                        id: 46,
                        text: '(a) The right to request from us access to your personal data (Art. 15 GDPR);',
                    },
                    {
                        id: 47,
                        text: '(b) The right to rectification or erasure of your personal data (Art. 16 and Art. 17 GDPR);',
                    },
                    {
                        id: 48,
                        text: '(c) The right to restriction of, or to object, the processing of your personal data (Art. 18 and Art. 21 GDPR);',
                    },
                    {
                        id: 49,
                        text: '(d) The right to data portability (Art. 20 GDPR).',
                    },
                    {
                        id: 50,
                        text: '10.2 Please note that your rights according to (b), (c) and (d) above will only exist under certain conditions pursuant to the GDPR.',
                    },
                    {
                        id: 51,
                        text: '10.3 You may lodge a complaint with a supervisory authority.',
                    },
                    {
                        id: 52,
                        header: '11. Data Transfers To Third Countries',
                        text: 'We might involve technical third-party providers for certain functionalities (e.g. Google Analytics as explained above) which are located in countries outside the European Economic Area (EEA). If providers located in countries outside the EEA have access to your personal data, we will take appropriate or suitable safeguards for the protection of your personal data, such as entering into Standard Data Protection Clauses pursuant to Art. 46 para. 2 lit. c) GDPR. We will provide access to the Standard Data Protection Clauses upon request.',
                    },
                    {
                        id: 53,
                        header: '12. Amendments To This Privacy Policy',
                        text: 'We reserve the right to amend this Privacy Policy. You will find the latest version of this Privacy Policy on our website.',
                    },
                ],
            },
        ],
    },
]