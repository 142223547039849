import React from 'react'

const TextComponent = ({DATA}) => {
    return (
      <>
        {DATA.map((item) => (
          <div key={item.id} className="flex flex-col justify-center align-center w-full mb-4">
            {/* Header */}
            <div className="flex flex-row justify-start align-center items-start lg:items-center">
              <div className="w-10 h-full p-2">
                <img src={item.Icon} alt={item.header} className="w-8 h-8 object-contain" />
              </div>
              <h1 className="text-lg w-full p-2 text-left font-bold mb-4 text-[#3CA5DC]">{item.header}</h1>
            </div>
  
            {/* Text */}
            <p className="text-lg w-full p-2 text-left font-regular lg:pl-10 mb-4 text-[#000000]">{item.sub}</p>
  
            {/* Icons */}
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-start align-start items-start lg:pl-10 lg:gap-2">
              {item.data.map((subItem) => (
                <div key={subItem.id} className="flex flex-row justify-start align-start items-start content-start gap-1 mb-5">
                  <div className="w-14 p-2">
                    <img src={subItem.icon} alt="Icon" className="w-full h-8 object-contain" />
                  </div>
                  <h1 className="text-lg p-2 text-left font-regular text-[#000000]">{subItem.text}</h1>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };
  

export default TextComponent