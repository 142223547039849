import React from 'react'
import Footer from '../../components/Footer';

const LegalDisclaimer = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                <span className='font-semibold text-md pb-1'>{item.header}
                                </span>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-4'>
                                        {item.subItems.map((subItem) => (
                                            <>
                                                <li key={subItem.id} className='w-full flex flex-col justify-start items-start pt-3'>
                                                    <span className='font-semibold text-md pb-1'>{subItem.header}</span> {subItem.text}
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default LegalDisclaimer

const DATA = [
    {
        id: 0,
        header: 'Legal Disclaimer',
        items: [
            {
                id: 0,
                subItems: [
                    {
                        id: 0,
                        header: 'Copyright protection',
                        text: 'Copyright in all material contained on this website is owned by the information provider, unless otherwise indicated.',
                    },
                    {
                        id: 1,
                        text: 'The information provider reserves all rights to the content and design of this website. Unless permitted in writing by the information provider, you may not reproduce, use or copy any content on this website including names, logos, designs and any other material/information. You may not commercially use any information, and you may not cause any of the information to be framed or embedded into another website without prior written permission from the information provider.',
                    },
                    {
                        id: 2,
                        text: 'All information referred to in this website is the property of its respective owner and should not be used in whole or in part in any jurisdiction without the prior written consent of the information provider.',
                    },
                    {
                        id: 3,
                        header: 'Endorsement',
                        text: 'Using a graphic, photo or illustration from this website does not explicitly or implicitly convey the information provider\'s endorsement of the site where it is used.',
                    },
                    {
                        id: 4,
                        header: 'External links',
                        text: 'Except where explicitly stated, the information provider is not responsible for the availability or content of external websites, nor does the information provider endorse, warrant or guarantee the products, services or information described or offered at these other Internet sites. It is the responsibility of the user to examine the copyright and licensing restrictions of linked pages and to secure all necessary permission.',
                    },
                    {
                        id: 5,
                        header: 'Liability',
                        text: 'For documents and software available from this server, the information provider does not warrant or assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information, apparatus, product or process disclosed.',
                    },
                ],
            },
        ],
    },
]