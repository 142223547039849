import React from 'react'
import Footer from '../../components/Footer';

const EULA = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-4'>
                                        {item.subItems.map((subItem) => (
                                            <li key={subItem.id} className='w-full flex flex-col justify-start items-start py-2'>
                                                <span className='font-semibold text-md'>{subItem.header}</span> {subItem.text}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default EULA

const DATA = [
    {
        id: 0,
        header: 'End-User License Agreement (EULA) For AllesHealth GmbH',
        items: [
            {
                id: 0,
                text: 'Effective Date: 01 July 2023',
            },
            {
                id: 1,
                text: 'Please read this End-User License Agreement (“EULA”) carefully before accessing or using AllesHealth, a HealthTech solution for patients and carers, designed to store medical records.',
            },
            {
                id: 2,
                text: '“AllesHealth GmbH” (“we,” “us,” or “our”) refers to AllesHealth GmbH with a mailing address of Apfhalterstrasse 7B, Muttenz 4132, Switzerland.',
            },
            {
                id: 3,
                text: 'This EULA is a legally binding agreement between you (the “User”, “End-user”) and AllesHealth, operated by AllesHealth GmbH.',
            },
            {
                id: 4,
                text: 'By accessing or using AllesHealth, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this EULA. If you do not agree with any of these terms, you may not use AllesHealth.',
                subItems: [
                    {
                        id: 0,
                        header: '1. Scope of License Grant:',
                        text: 'Subject to your compliance with the terms of this EULA, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use AllesHealth for personal, non-commercial purposes. This license does not entitle you to any ownership rights or intellectual property rights in AllesHealth.',
                    },
                    {
                        id: 1,
                        header: '2. Privacy and Data Security:',
                        text: 'Your privacy and the security of your data are of utmost importance to us. By using AllesHealth, you agree to the terms of our Privacy Policy, which is incorporated into this EULA by reference. Please review the Privacy Policy carefully to understand how we collect, use, and protect your personal and medical information.',
                    },
                    {
                        id: 2,
                        header: '3. User Responsibilities:',
                        text: '',
                    },
                    {
                        id: 3,
                        text: 'a. Registration: To access and use AllesHealth, you may be required to create an account. You are responsible for providing accurate and complete information during the registration process and maintaining the confidentiality of your account credentials.',
                    },
                    {
                        id: 4,
                        text: 'b. Medical Records: You understand and agree that you are solely responsible for uploading, managing, and maintaining your medical records within AllesHealth. It is your responsibility to ensure the accuracy, legality, and relevance of the information you upload.',
                    },
                    {
                        id: 5,
                        text: 'c. Compliance with Laws: You agree to use AllesHealth in compliance with all applicable laws, rules, and regulations. You shall not use AllesHealth for any unlawful or unauthorized purposes.',
                    },
                    {
                        id: 6,
                        header: '4. Intellectual Property:',
                        text: 'AllesHealth and all related materials, including but not limited to software, graphics, logos, trademarks, and content, are owned by or licensed to us and are protected by intellectual property laws. You shall not use, reproduce, modify, distribute, or create derivative works based on AllesHealth without our express written permission.',
                    },
                    {
                        id: 7,
                        header: '5. Termination:',
                        text: 'We reserve the right to suspend or terminate your access to AllesHealth at any time, with or without cause, and without notice. Upon termination, your license to use AllesHealth shall cease, and you must immediately stop using the platform and delete any copies of AllesHealth in your possession.',
                    },
                    {
                        id: 8,
                        header: '6. Warranties, Support and Maintenance:',
                        text: 'AllesHealth is provided “as is\' \' and without warranties of any kind, whether express or implied. We do not guarantee the accuracy, completeness, or reliability of any information stored within AllesHealth or its suitability for any specific purpose. You use AllesHealth at your own risk. Neither Apple nor Google shall be held liable to provide or bear burden of any support, nor maintenance to the end-user.',
                    },
                    {
                        id: 9,
                        header: '7. Limitation of Liability:',
                        text: 'In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to your use of AllesHealth, even if we have been advised of the possibility of such damages. Furthermore the same limitation of liability extends to Apple, Google and any of their subsidiaries and services; where they shall not be liable under any circumstances.',
                    },
                    {
                        id: 10,
                        header: '8. Legal Compliance:',
                        text: 'The end-user must guarantee and affirm that (i) they are not situated in a country subject to a U.S. Government embargo or classified as a “terrorist supporting” nation by the U.S. Government; and (ii) they are not included in any U.S. Government list of restricted or prohibited entities.',
                    },
                    {
                        id: 11,
                        header: '9. Third Party Terms of Use Compliance:',
                        text: 'The end-user must guarantee and affirm that: (i) the user shall not modify nor attempt to intentionally or unintentionally misuse AllesHealth application and services. Under this condition, AllesHealth shall maintain compliance of the Third Party Services Terms of Use applicable for the operation of the platform, whilst remaining compliant to AllesHealth Data Privacy Policies. (ii) The End-user must comply with any personally applicable third-party terms of agreement when using the application.',
                    },
                    {
                        id: 12,
                        header: '10. Modification of Terms:',
                        text: 'We may update and modify this EULA from time to time. Any changes will be effective upon posting the revised EULA on AllesHealth or providing notice to you. Your continued use of AllesHealth after such changes constitutes your acceptance of the revised EULA.',
                    },
                    {
                        id: 13,
                        header: '11. Governing Law and Jurisdiction:',
                        text: 'This EULA shall be governed by and construed in accordance with the laws of Basel Land, Switzerland, without regard to its conflicts of law principles. Any disputes arising under this EULA shall be subject to the exclusive jurisdiction of the courts located in Basel Land, Switzerland',
                    },
                ],
            },
            {
                id: 5,
                text: 'If you have any questions or concerns regarding this EULA, please contact us at [info@alleshealth.com]',
            },
            {
                id: 6,
                text: 'By clicking “I agree” or by accessing and using AllesHealth, you acknowledge that you have read, understood, and agree to be bound by this EULA.',
            },
        ],
    },
]