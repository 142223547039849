import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import bgImage from "../assets/ContactUs/bgImage.jpg";
import tele from "../assets/ContactUs/teleIcon.png";
import mssg from "../assets/ContactUs/mssgIcon.png";
import LandingTile2 from "../components/LandingTile2";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Constant from "../config/Constant";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const BASE_URL = Constant.DEFAULT_APP_URL;
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Message: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    Email: "",
    Message: "",
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  function submitHandler(event) {
    event.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      } else if (
        typeof formData[key] === "string" &&
        formData[key].trim() === ""
      ) {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("Errors:", newErrors);
      return;
    }

    const basicData = {
      Name: formData.Name,
      Email: formData.Email,
      Message: formData.Message,
    };
    // dispatch(addContactUs(basicData, dispatch));
    handleContactUs(basicData);
  }

  const handleContactUs = async (data) => {
    const toastId = toast.loading("Sending Message...");
    try {
      const response = await axios.post(BASE_URL + "/contact/contactUs", data);
      console.log(response);
      toast.dismiss(toastId);
      toast.success("Message Sent Successfully");
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error("Message not sent. Please try again");
    }
  };

  return (
    <div className="bg-white min-h-screen scroll-smooth">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - Alleshealth</title>
        <meta
          name="description"
          content="Reach out to Alleshealth / Contact Alleshealth"
        />
        <meta
          name="keywords"
          content="Alles, alles, allesheath, Alleshealth, health, contact, contact alleshealth, Contact Alleshealth"
        />
      </Helmet>

      <LandingTile2
        bgImage={bgImage}
        headerText={"CONTACT US"}
        headerColor={"#FFFFFF"}
        headerFont={"text-[4vw]"}
        bottomColor={"#35ABF4"}
        mobileText={"text-center"}
      />

      {/* CONTENT */}
      <div className="flex w-[80vw] mx-[10vw] my-8 lg:my-[8vh] flex-col justify-center items-center">
        <h1 className="font-regular mb-10 text-lg text-black text-left">
          Reach out to us at:
        </h1>

        <a
          href="https://t.me/alleshealth"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#F3F5F6] py-5 px-10 lg:px-2 flex flex-col lg:flex-row justify-center items-center rounded-3xl lg:rounded-l-full shadow-xl w-[80vw] mx-[10vw] sm:max-lg:w-[40vw] sm:max-lg:mx-[20vw] lg:w-[25vw] lg:relative gap-5 cursor-pointer hover:scale-105 duration-500"
        >
          <div className="flex flex-row justify-center items-center w-12 lg:w-[4vw] h-auto lg:absolute lg:left-5">
            <img
              src={tele}
              alt="Telegram"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="flex flex-col justify-center items-center lg:ml-[2vw]">
            <h1 className="font-regular md:text-sm xl:text-lg text-[#000000] text-center">
              Telegram
            </h1>
            <h1 className="font-regular md:text-sm xl:text-lg text-[#0A95DE] text-center">
              @alleshealth
            </h1>
          </div>
        </a>
        <a
          href="mailto:info@alleshealth.com"
          className="bg-[#F3F5F6] mt-10 py-5 px-10 lg:px-2 flex flex-col lg:flex-row justify-center items-center rounded-3xl lg:rounded-l-full shadow-xl w-[80vw] mx-[10vw] sm:max-lg:w-[40vw] sm:max-lg:mx-[20vw]-lg lg:w-[25vw] lg:relative gap-5 cursor-pointer hover:scale-105 duration-500"
        >
          <div className="flex flex-row justify-center items-center  w-12 lg:w-[4vw] h-auto lg:absolute lg:left-5">
            <img
              src={mssg}
              alt="E-mail"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="flex flex-col justify-center items-center lg:ml-[2vw]">
            <h1 className="font-regular md:text-sm xl:text-lg text-[#000000] text-center">
              E-mail
            </h1>
            <h1 className="font-regular md:text-sm xl:text-lg text-[#0A95DE] text-center">
              info@alleshealth.com
            </h1>
          </div>
        </a>

        <h1 className="font-regular my-16 text-lg text-black text-left">
          Send us a Message
        </h1>

        {/* Message */}
        <div className="bg-[#F3F5F6] flex w-[80vw] lg:w-[60%] py-16 mx-[10vw] lg:mx-[20%] flex-col justify-center items-center rounded-2xl shadow-xl">
          <form className="w-full px-1 lg:px-10">
            {/* Name */}
            <div className="mb-6 mx-[10%]">
              <label className="flex flex-col lg:flex-row justify-center items-start">
                <p className="text-[#3CA5DC] text-md font-bold w-full lg:w-1/4 text-left font-open p-2 mb-2">
                  Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Name"
                  value={formData.Name}
                  onChange={handleOnChange}
                  maxLength={30}
                  placeholder="Input Name"
                  className={`w-full lg:w-3/4 text-black text-xs font-normal font-open bg-[#FFFFFF] rounded-md p-4 border border-1 ${errors.Message ? "border-red-500" : "border-[#3CA5DC]"
                    }  focus:outline-[#3CA5DC] focus:ring-1 focus:ring-[#3CA5DC] focus:border-transparent`}
                />
              </label>
            </div>

            {/* Email */}
            <div className="mb-6 mx-[10%]">
              <label className="flex flex-col lg:flex-row justify-center items-start">
                <p className="text-[#3CA5DC] text-md font-bold text-left w-full lg:w-1/4 p-2 font-open mb-2">
                  E-mail
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Email"
                  value={formData.Email}
                  onChange={handleOnChange}
                  maxLength={30}
                  placeholder="Input Email"
                  className={`w-full lg:w-3/4 text-black text-xs font-normal font-open bg-[#FFFFFF] rounded-md p-4 border border-1 ${errors.Message ? "border-red-500" : "border-[#3CA5DC]"
                    }  focus:outline-[#3CA5DC] focus:ring-1 focus:ring-[#3CA5DC] focus:border-transparent`}
                />
              </label>
            </div>

            {/* description */}
            <div className="mb-6 mx-[10%]">
              <label className="flex flex-col lg:flex-row justify-center items-start">
                <p className="text-[#3CA5DC] text-md font-bold text-left pt-5 w-full lg:w-1/4 font-open mb-2">
                  Message
                  <sup className="text-red-600">*</sup>
                </p>
                <textarea
                  style={{ paddingBottom: "4rem" }}
                  type="text"
                  name="Message"
                  value={formData.Message}
                  onChange={handleOnChange}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  maxLength={300}
                  placeholder=""
                  className={`w-full lg:w-3/4 text-black text-xs font-normal font-open bg-[#FFFFFF] rounded-md p-4 border border-1 ${errors.Message ? "border-red-500" : "border-[#3CA5DC]"
                    }  focus:outline-[#3CA5DC] focus:ring-1 focus:ring-[#3CA5DC] focus:border-transparent`}
                />
              </label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              style={{ backgroundColor: "#3CA5DC" }}
              className="font-semibold text-white w-[90%] mx-[5%] px-4 py-2 rounded-md mb-2 mt-10 shadow-xl cursor-pointer hover:scale-105 duration-500"
              onClick={submitHandler}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>

      <hr className="mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black" />

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default ContactUs;
