import React from 'react'
import logo from '../assets/logo.png';
import loopIcon from '../assets/Home/loopIcon.png';
import twitterIcon from '../assets/Home/twitterIcon.png';
import youtubeIcon from '../assets/Home/youtubeIcon.png';
import linkedinIcon from '../assets/Home/linkedinIcon.png';
import instaIcon from '../assets/Home/instaIcon.png';
import telegramIcon from '../assets/Home/telegramIcon.png';
import msgIcon from '../assets/Home/msgIcon.png';
import { NavLink } from "react-router-dom";

const Footer = () => {
    const linkedinUrl = 'https://www.linkedin.com/company/alleshealth/';
    const instaUrl = 'https://www.instagram.com/alleshealth/';
    const telegramUrl = 'https://telegram.me/alleshealth';
    const loopUrl = 'https://www.facebook.com/AllesHealthCH';
    const twitterUrl = 'https://twitter.com/AllesHealthCH';
    const youtubeUrl = 'https://www.youtube.com/@alleshealth';

    const handleOnClick = (url) => {
        window.open(url, '_blank');
    };
    return (
        <footer className='relative flex flex-col md:flex-row w-[90vw] mx-[5vw] md:pb-[10vh] pl-[3vw] mb-5 justify-between items-start'>

            {/* LOGO */}
            <div className="flex flex-col text-left justify-start align-start w-full">
                <div className="company-logo flex justify-start align-start my-4">
                    <img src={logo} alt="company-logo" className="w-60 h-14 object-contain" />
                </div>
                <p className="text-md font-regular text-[#000000] text-left px-4 py-3">
                    Copyright © 2024<br />
                    AllesHealth - All Rights Reserved.<br />
                    EU Transparency Register: 760010651338-97<br />
                </p>
                <a href="mailto:info@alleshealth.com" className="flex flex-row justify-start items-center gap-2 px-4">
                    <img src={msgIcon} alt="Message Icon" className="w-12 md:w-[3vw] h-12 object-contain" />
                    <p className="text-md font-bold text-[#000000] text-left py-3">
                        Reach out at : <br />info@alleshealth.com
                    </p>
                </a>
            </div>

            {/* OUR POLICIES */}
            <div className="flex flex-col text-left justify-start align-start w-full">
                <h1 className="text-lg font-bold text-[#42B8F7] text-left px-4 py-3">Our Policies</h1>
                <ul className="text-md font-regular text-[#000000] text-left px-4 py-3">
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/data-privacy-policy"}>Data Privacy Policy</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/shipping-policy"}>Shipping Policy</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/refund-cancellation-policy"}>Refund & Cancellation Policy</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/terms-conditions"}>Terms & Conditions</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/privacy-and-security-policy"}>Privacy and Security Policy</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/end-user-license-agreement-eula"}>End-User License Agreement (EULA)</NavLink></li>
                </ul>
            </div>

            {/* IMPORTANT LINKS */}
            <div className="flex flex-col text-left justify-start align-start w-full">
                <h1 className="text-lg font-bold text-[#42B8F7] text-left px-4 py-3">Important Links</h1>
                <ul className="text-md font-regular text-[#000000] text-left px-4 py-3">
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/ForPatientsCaregivers"}>For Patients & Carers</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/ForHospitalsClinics"}>For Hospitals & Clinics</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/OurProducts"}>Our Products</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/Sunita"}>Program SUNITA</NavLink></li>

                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/careers"}>Careers</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/aboutus"}>About Us</NavLink></li>
                    <li className="cursor-pointer hover:text-[#3CA5DC] hover:font-semibold duration-100"><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                </ul>
            </div>

            {/* OUR SOCIALS */}
            <div className="flex flex-col text-left justify-center align-start w-full">
                <h1 className="text-lg font-bold text-[#42B8F7] text-left px-4 py-3">Our Socials</h1>
                <div className="flex flex-col md:max-lg:flex-row justify-center align-center md:max-lg:justify-start">
                    <div className="flex flex-row md:max-lg:flex-col justify-start items-center gap-5 px-4 md:max-lg:px-2 py-3">
                        <a href={loopUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={loopIcon}
                                alt="Loop Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                        <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={twitterIcon}
                                alt="Twitter Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                        <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={youtubeIcon}
                                alt="Youtube Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                    </div>
                    <div className="flex flex-row md:max-lg:flex-col justify-start items-center px-4 md:max-lg:px-2 md:max-lg:py-3 gap-5">
                        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={linkedinIcon}
                                alt="Linkedin Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                        <a href={instaUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={instaIcon}
                                alt="Insta Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                        <a href={telegramUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={telegramIcon}
                                alt="Telegram Icon"
                                className="w-12 h-12 object-contain cursor-pointer"
                            />
                        </a>
                    </div>
                </div>
            </div>

            {/* LEGAL DISCALIMER */}
            <h1 className="text-lg font-bold text-[#42B8F7] text-left px-4 py-10 md:py-5 md:absolute md:bottom-0 md:right-[20vw]"><NavLink to={"/legal-disclaimer"}>Legal Disclaimer</NavLink></h1>
        </footer>
    )
}

export default Footer