import React from 'react'
import Footer from '../../components/Footer';

const PolicySecurity = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-4'>
                                        {item.subItems.map((subItem) => (
                                            <li key={subItem.id} className='w-full flex flex-col justify-start items-start py-2'>
                                                <span className='font-semibold text-md pb-4'>{subItem.header}</span> {subItem.text}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default PolicySecurity

const DATA = [
    {
        id: 0,
        header: 'Privacy And Security Policy For AllesHealth Mobile App',
        items: [
            {
                id: 0,
                text: 'Effective Date: 01 June 2023',
            },
            {
                id: 1,
                text: 'This Privacy and Security Policy (“Policy”) governs the collection, use, storage, and disclosure of personal information provided by users of the AllesHealth Mobile App (the “App”) for Patients & Carers. The App is provided by AllesHealth GmbH (“AllesHealth,” “we,” or “us”) and marketed by Swiss AllesHealth India Pvt Ltd in India. This Policy applies to all individuals who access or use the App (“users,” “you,” or “your”).',
                subItems: [
                    {
                        id: 0,
                        header: 'Introduction',
                        text: '1.1 AllesHealth is committed to protecting the privacy and security of your personal information. This Policy describes how we collect, use, store, and disclose your information when you use the App.',
                    },
                    {
                        id: 1,
                        text: '1.2 By using the App, you consent to the collection and processing of your personal information in accordance with this Policy. If you do not agree with the practices outlined in this Policy, please refrain from using the App.',
                    },
                    {
                        id: 2,
                        header: 'Information Collection',
                        text: '2.1 Personal Information: We collect personal information that you provide to us voluntarily when using the App. This includes, but is not limited to, your name, contact details, medical history, treatment records, and any other information necessary for the provision of healthcare services through the App.',
                    },
                    {
                        id: 3,
                        text: '2.2 Sensitive Information: In order to deliver appropriate healthcare services, you may choose to provide sensitive personal information, such as information about your health condition, medication history, or allergies. We will handle this information with utmost care and in accordance with applicable laws and regulations.',
                    },
                    {
                        id: 4,
                        text: '2.3 Device Information: We may collect information about the device you use to access the App, such as the device type, unique device identifier, IP address, operating system, and mobile network information.',
                    },
                    {
                        id: 5,
                        text: '2.4 Location Information: With your explicit consent, we may collect and process your precise location information to provide location-based services within the App.',
                    },
                    {
                        id: 6,
                        text: '2.5 Cookies and Similar Technologies: We may use cookies, web beacons, and other similar technologies to enhance your experience, analyze usage patterns, and improve the App\'s functionality. You can manage your cookie preferences through your device settings or the App\'s settings.',
                    },
                    {
                        id: 7,
                        header: 'Use of Information',
                        text: '3.1 Service Provision: We use the information collected to provide you with access to healthcare services, facilitate communication between you and healthcare providers, deliver personalized content, and improve the functionality of the App.',
                    },
                    {
                        id: 8,
                        text: '3.2 Communication: We may use your contact information to send you important updates, newsletters, promotional materials, or other relevant communications related to your use of the App. You have the option to opt-out of receiving promotional materials.',
                    },
                    {
                        id: 9,
                        text: '3.3 Research and Analytics: We may anonymize and aggregate user information to conduct research, statistical analysis, and improve our services. This data will not personally identify you.',
                    },
                    {
                        id: 10,
                        text: '3.4 Legal Compliance: We may use and disclose your information as required by applicable laws, regulations, legal processes, or governmental requests.',
                    },
                    {
                        id: 11,
                        header: 'Information Sharing',
                        text: '4.1 Healthcare Providers: With your consent, we may share your personal information with healthcare providers and professionals involved in your treatment and care through the App. We will take appropriate measures to ensure that they handle your information securely and in compliance with applicable privacy laws.',
                    },
                    {
                        id: 12,
                        text: '4.2 Third-Party Service Providers: We may engage trusted third-party service providers to perform services on our behalf, such as hosting, data analysis, customer support, and marketing. These providers will have access to your information solely for the purpose of performing their designated functions and will be bound by strict confidentiality obligations.',
                    },
                    {
                        id: 13,
                        text: '4.3 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal information.',
                    },
                    {
                        id: 14,
                        text: '4.4 Legal Requirements: We may disclose your information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.',
                    },
                    {
                        id: 15,
                        header: 'Data Security',
                        text: '5.1 We implement reasonable technical and organizational measures to protect your personal information against unauthorized access, loss, or alteration. These measures include encryption, access controls, firewalls, and regular security assessments. However, no security measures can guarantee absolute protection, and we cannot ensure or warrant the security of any information you transmit to us.',
                    },
                    {
                        id: 16,
                        text: '5.2. Also check out Data Privacy Policy to learn more.',
                    },
                    {
                        id: 17,
                        header: 'Retention of Information',
                        text: '6.1 We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Policy, or as required by law. We will securely dispose of or anonymize your personal information when it is no longer needed.',
                    },
                    {
                        id: 18,
                        header: 'Your Rights',
                        text: '7.1 You have the right to access, correct, or delete your personal information held by us. You may also withdraw your consent for the processing of your information or object to its processing under certain circumstances.',
                    },
                    {
                        id: 19,
                        text: '7.2 Marketing Communications: You have the right to opt-out of receiving marketing communications from us. You can exercise this right by following the instructions provided in the communication or by contacting us directly.',
                    },
                    {
                        id: 20,
                        header: 'Children\'s Privacy',
                        text: '8.1 The App is not intended for use by individuals under the age of 18. If you become aware that a child has provided us with their personal information without parental consent, please contact us to have the information removed from our records.',
                    },
                    {
                        id: 21,
                        header: 'International Data Transfers',
                        text: '9.1 Your personal information may be transferred to, processed, and stored in countries outside your jurisdiction, including countries that may have different data protection laws than your own. We will ensure that appropriate safeguards are in place to protect your personal information when it is transferred internationally.',
                    },
                    {
                        id: 22,
                        header: 'Changes to this Policy',
                        text: '10.1 We reserve the right to modify this Policy at any time. We will notify you of any material changes through the App or other means. Your continued use of the App after the changes have been notified will signify your acceptance of the updated Policy.',
                    },
                    {
                        id: 23,
                        header: 'Contact Us',
                        text: '11.1 If you have any questions, concerns, or requests regarding this Policy or the processing of your personal information, please contact us at info@alleshealth.com',
                    },
                ],
            },
            {
                id: 2,
                text: 'By using the AllesHealth Mobile App, you acknowledge that you have read, understood, and agreed to the terms of this Privacy and Security Policy.',
            },
        ],
    },
]