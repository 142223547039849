import React from 'react'
import Footer from '../../components/Footer';

const Shipping = () => {
    return (
        <div>
            {DATA.map((policy) => (
                <div key={policy.id} className="w-[80vw] mx-[10vw] flex flex-col justify-start mt-[4vw] mb-[8vw]">
                    <h1 className="text-4xl font-semibold text-[#000000] text-center mb-[4vw]">{policy.header}</h1>
                    <ul>
                        {policy.items.map((item) => (
                            <li key={item.id} className='w-full flex flex-col justify-start items-start py-2 text-left'>
                                {item.text}
                                {item.subItems && (
                                    <ul className='w-full flex flex-col justify-start items-start py-2'>
                                        {item.subItems.map((subItem) => (
                                            <div key={subItem.id} className='w-full flex flex-col justify-start items-start'>
                                                {subItem.header &&
                                                    <li className='font-semibold text-md mt-4'>{subItem.header}</li>
                                                }
                                                <li className='py-1'>{subItem.text}</li>
                                            </div>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default Shipping

const DATA = [
    {
        id: 0,
        header: 'Shipping Policy',
        items: [
            {
                id: 0,
                subItems: [
                    {
                        id: 0,
                        header: 'Eligibility for Health Rewards:',
                        text: 'Users of the AllesHealth app may be eligible to earn health rewards based on their participation in various health and wellness activities as specified within the app.',
                    },
                    {
                        id: 1,
                        text: 'The specific activities and criteria for earning health rewards will be clearly outlined within the app.',
                    },
                    {
                        id: 2,
                        header: 'Reward Fulfillment:',
                        text: 'Health rewards earned through the AllesHealth app will be fulfilled through digital means, such as virtual gift cards, redeemable codes, or electronic vouchers.',
                    },
                    {
                        id: 3,
                        text: 'Physical shipping of rewards may be applicable for certain merchandise or products, as determined by AllesHealth.',
                    },
                    {
                        id: 4,
                        header: 'Shipping Availability:',
                        text: 'Physical shipping of health rewards is subject to availability and is currently limited to specific regions or countries.',
                    },
                    {
                        id: 5,
                        text: 'The availability of physical shipping options will be clearly indicated within the AllesHealth app.',
                    },
                    {
                        id: 6,
                        header: 'Shipping Fees and Charges:',
                        text: 'Shipping fees, if applicable, will be clearly communicated to users before confirming their reward redemption.',
                    },
                    {
                        id: 7,
                        text: 'The user will be responsible for any applicable shipping charges unless otherwise specified.',
                    },
                    {
                        id: 8,
                        header: 'Shipping Address:',
                        text: 'Users must provide accurate and up-to-date shipping information, including the correct shipping address, during the reward redemption process.',
                    },
                    {
                        id: 9,
                        text: 'AllesHealth will not be responsible for any delays or issues arising from incorrect or incomplete shipping address details provided by the user.',
                    },
                    {
                        id: 10,
                        header: 'Shipping Timelines:',
                        text: 'AllesHealth will strive to fulfill physical rewards and initiate shipping in a timely manner.',
                    },
                    {
                        id: 11,
                        text: 'Shipping timelines may vary depending on the user\'s location and the availability of the specific reward item.',
                    },
                    {
                        id: 12,
                        text: 'Users will be provided with estimated shipping timelines during the reward redemption process.',
                    },
                    {
                        id: 13,
                        header: 'Tracking and Delivery:',
                        text: 'For physical rewards that require shipping, AllesHealth will provide users with tracking information, where applicable.',
                    },
                    {
                        id: 14,
                        text: 'Users can use the provided tracking details to monitor the delivery status of their rewards.',
                    },
                    {
                        id: 15,
                        text: 'AllesHealth will not be responsible for any delays, damages, or issues that may occur during the shipping and delivery process, as it is beyond our control.',
                    },
                    {
                        id: 16,
                        header: 'International Shipping:',
                        text: 'International shipping of health rewards may be subject to customs duties, taxes, or other fees imposed by the recipient\'s country.',
                    },
                    {
                        id: 17,
                        text: 'Any additional costs related to international shipping and customs clearance are the responsibility of the user.',
                    },
                ],
            },
        ],
    },
]